body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.round-marker {
  border-radius: 50%;
  background: white;
  width: 40px;
  height: 40px;
  position: relative;
  top: -20px;
  left: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  border: 3px solid #6c98da;
  box-shadow: 2px 2px 4px rgba(165, 162, 162, 0.25);
}

.marker-kns-vns {
  display: flex;
  padding: 8px 16px 8px 8px;
  background: white;
  border-radius: 12px;
  box-shadow: 2px 2px 4px rgba(165, 162, 162, 0.25);
  min-width: 102px;
  align-items: center;
}
.marker-kns-vns .icon {
  padding-right: 6px;
}
.marker-kns-vns .text {
  text-align: center;
}

div.leaflet-bottom.leaflet-right a svg {
  display: none !important;
}

::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background: #6c98da !important;
}

::-webkit-scrollbar-track {
  background: #e5f3fb !important;
  border-radius: 4px !important;
}

.infinite-rotation {
  transition: all 0.5s ease;
  transform-origin: 53px 52px;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
}
